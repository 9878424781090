<template>
  <label class="checkbox" style="margin: auto 0; width: 100%;">
    <input type="checkbox" v-model="checked" :style="{
      margin: 'auto 0',
      marginRight: label ? '.5rem' : ''
    }"> {{ label }}
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    // data: Object,
    isChecked: {
      required: false,
      type: Boolean
    },
    isCentered: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checked: false
    }
  },
  created () {
    this.checked = this.isChecked
  },
  watch: {
    checked (isChecked) {
      this.$emit('isChecked', {
        ref: this.$el,
        isChecked: isChecked
      })
    }
  }
}
</script>
